/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import CustomInteriorPage from "../components/page-layouts/custom-interior-page"
import { graphql } from "gatsby"
import HeroSection from "../components/home-sections/hero-section"
import FeatureSection from "../components/home-sections/features-sections"

function PageData() {
  return (
    <div>
      <HeroSection />
      <FeatureSection />
    </div>
  )
}

export default function HomePage({ data }) {

  const siteTitle = data.site.siteMetadata.title

  return <CustomInteriorPage body={PageData()} seo={siteTitle} />
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
